import { useState } from "react";

const SVGItchioCircleIcon = (props) => {

  const [fillCollor, setFillCollor] = useState(props.fill);

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 200 200" 
    
    onMouseOver={() => setFillCollor(props.hovercolor)} 
    onMouseOut={() => setFillCollor(props.fill)} 
    style={{width: props.psize, heigth: props.psize}}>

<circle fill={fillCollor} cx="100" cy="99.8" r="100"/>
<g>
	<path fill="white" d="M75.87,233.25c0-8.66,7.03-15.69,15.69-15.69c8.67,0,15.71,7.04,15.7,15.71c-0.01,8.66-7.05,15.68-15.7,15.67
		C82.89,248.94,75.87,241.91,75.87,233.25z M80.72,233.01c-0.02,0.06-0.03,0.08-0.03,0.1c0,1.62-0.01,3.24,0,4.86
		c0,0.09,0.11,0.22,0.2,0.25c0.73,0.31,1.47,0.6,2.21,0.88c0.25,0.1,0.39,0.23,0.48,0.5c0.57,1.83,2.32,3,4.24,2.86
		c1.9-0.13,3.42-1.55,3.76-3.47c0.02-0.14,0.15-0.3,0.27-0.38c1.54-1.13,3.1-2.24,4.64-3.37c0.27-0.2,0.53-0.33,0.88-0.32
		c0.25,0.01,0.5-0.06,0.74-0.11c2.7-0.57,4.58-3.15,4.29-5.88c-0.26-2.53-2.22-4.5-4.82-4.87c-3.13-0.44-6.07,2-6.22,5.15
		c-0.01,0.14-0.05,0.29-0.13,0.4c-1.05,1.5-2.1,2.99-3.15,4.49c-0.12,0.17-0.25,0.2-0.44,0.21c-0.49,0.04-1.03,0-1.44,0.22
		c-0.68,0.36-1.24,0.2-1.87-0.07C83.16,233.95,81.95,233.5,80.72,233.01z"/>
	<path fill="white" d="M96.88,233.15c-2.07-0.02-3.71-1.67-3.68-3.72c0.02-2.01,1.72-3.62,3.78-3.59c2.04,0.03,3.65,1.69,3.63,3.74
		C100.58,231.56,98.9,233.17,96.88,233.15z M96.92,231.69c1.22,0,2.21-1,2.2-2.22c-0.01-1.21-1-2.18-2.21-2.18
		c-1.21,0.01-2.19,1-2.19,2.2C94.72,230.7,95.71,231.69,96.92,231.69z"/>
	<path fill="white" d="M84.87,239.82c0.57,0.22,1.08,0.44,1.6,0.62c1.02,0.35,2.13-0.04,2.74-0.92c0.59-0.86,0.57-2.03-0.12-2.83
		c-0.26-0.3-0.64-0.53-1-0.73c-0.35-0.2-0.75-0.31-1.14-0.47c0.96-0.49,2.69,0.36,3.28,1.6c0.73,1.52,0.08,3.35-1.44,4.06
		C87.35,241.83,85.49,241.22,84.87,239.82z"/>
</g>
<g>
	<path fill="white" d="M82.59,37.54c11.87,0,23.75,0,35.62,0c0.16,0.02,0.31,0.06,0.47,0.07c10.56,0.22,21.11,0.48,31.67,0.63
		c1.78,0.02,3.19,0.52,4.55,1.63c4.23,3.45,7.97,7.38,11.39,11.6c2.07,2.56,4.15,5.13,5.29,8.29c0,2.62,0,5.23,0,7.85
		c-0.03,0.07-0.08,0.14-0.09,0.22c-0.51,4.01-2.45,7.23-5.45,9.85c-2.17,1.89-4.65,3.23-7.56,3.44
		c-7.38,0.54-14.11-4.75-15.42-12.04c-0.06-0.36-0.13-0.72-0.19-1.07c-0.79,6.9-6.22,12.41-13.09,13.1
		c-3.11,0.31-6.05-0.31-8.72-1.98c-4.15-2.59-6.36-6.4-6.98-11.21c-0.85,7.64-7.61,13.27-14.95,13.27c-7.32,0-14.1-5.61-14.9-13.11
		c-1.53,7.78-5.91,12.6-14.3,13.09c-8.37-0.48-12.9-5.19-14.55-13.1c-0.01,0.03-0.04,0.1-0.05,0.18c-0.44,3.58-2.03,6.58-4.67,9.01
		c-4.27,3.92-11.19,5.75-17.16,1.41c-3.82-2.78-6.3-6.43-6.74-11.21c-0.19-2.02,0-4.06-0.09-6.09c-0.06-1.43,0.33-2.69,1.04-3.89
		c0.28-0.47,0.55-0.94,0.84-1.39c2.3-3.49,4.99-6.66,7.86-9.69c2.59-2.74,5.27-5.37,8.37-7.54c0.49-0.34,1-0.55,1.61-0.57
		c2.19-0.07,4.38-0.2,6.57-0.24c8.87-0.15,17.74-0.26,26.62-0.4C80.59,37.62,81.59,37.57,82.59,37.54z"/>
	<path fill="white" d="M55.03,77.64c3.37,5.36,8.21,8.23,14.53,8.22c6.34-0.01,11.11-3.01,14.51-8.3c3.52,5.47,8.47,8.32,15.03,8.33
		c6.57,0.01,11.54-2.8,15.09-8.33c3.39,5.26,8.16,8.28,14.51,8.3c6.33,0.01,11.16-2.85,14.53-8.21c3.96,5.57,9.14,8.69,16.3,8.13
		c0.05,0.46,0.14,1.07,0.18,1.67c0.26,4.24,0.66,8.47,0.72,12.71c0.14,10.4,0.17,20.8,0.13,31.2c-0.02,6.06-0.34,12.11-1.19,18.11
		c-0.53,3.75-1.18,7.47-2.77,10.94c-0.5,1.09-1.2,2.09-1.87,3.08c-0.19,0.27-0.6,0.49-0.94,0.54c-4.23,0.68-8.44,1.47-12.69,1.94
		c-5.4,0.59-10.81,1.05-16.24,1.29c-7.92,0.35-15.85,0.58-23.77,0.62c-7.38,0.04-14.77-0.07-22.15-0.38
		c-6.85-0.28-13.69-0.84-20.53-1.39c-4.67-0.37-9.3-1.08-13.89-2.05c-0.48-0.1-0.83-0.31-1.14-0.7c-1.46-1.83-2.28-3.96-2.93-6.17
		c-1.14-3.91-1.69-7.93-2.07-11.98c-0.98-10.38-0.75-20.78-0.71-31.18c0.02-5.59,0.08-11.19,0.26-16.77
		c0.12-3.72,0.47-7.43,0.73-11.14c0.01-0.16,0.06-0.31,0.06-0.35C45.84,86.33,51.04,83.25,55.03,77.64z M76.59,135.46
		c15.02,2.18,30.06,2.17,45.14,0c0.02,0.33,0.04,0.62,0.06,0.92c0.02,0.3,0.02,0.61,0.06,0.9c1.04,7.38,5.08,12.39,12,15.07
		c3.99,1.54,8.14,1.59,12.31,1.04c1.39-0.18,2.71-0.61,3.83-1.5c1.72-1.39,2.25-3.32,2.38-5.4c0.21-3.35-0.43-6.61-1.3-9.82
		c-2.09-7.67-4.28-15.32-6.36-23c-1.25-4.6-3.07-8.92-5.76-12.86c-1.4-2.06-3.07-3.88-5.13-5.31c-2.33-1.62-4.9-2.45-7.76-2.4
		c-2.31,0.04-4.63,0.05-6.87,0.77c-2.74,0.87-4.93,2.57-6.9,4.61c-0.18,0.19-0.5,0.36-0.74,0.35c-2.41-0.14-4.81-0.39-7.22-0.49
		c-5.84-0.23-11.67-0.12-17.48,0.49c-0.4,0.04-0.66-0.04-0.94-0.34c-1.42-1.53-3.01-2.84-4.88-3.8c-2.26-1.16-4.7-1.39-7.18-1.58
		c-5.29-0.41-9.49,1.57-12.87,5.52c-3.15,3.68-5.33,7.93-6.69,12.52c-2.48,8.38-4.75,16.81-7.05,25.24
		c-0.91,3.32-1.56,6.69-1.38,10.18c0.2,3.84,2.1,6.12,5.87,6.76c3.95,0.67,7.91,0.62,11.75-0.65c6.71-2.21,11.02-6.72,12.61-13.67
		C76.38,137.85,76.44,136.66,76.59,135.46z"/>
	<path fill="white" d="M99.17,107.03c5.29,5.13,10.79,10.01,15.18,15.91c-0.04,0.05-0.08,0.11-0.11,0.16
		c-2.76-0.11-5.52-0.22-8.33-0.34c0,0.61,0,1.14,0,1.68c0,1.69-0.04,3.38,0.02,5.07c0.02,0.64-0.22,0.81-0.77,0.81
		c-0.54,0-1.09,0.02-1.63,0.02c-2.94,0-5.87,0.01-8.81,0c-0.66,0-1.33-0.03-1.99-0.09c-0.13-0.01-0.36-0.22-0.36-0.34
		c-0.02-2.36-0.02-4.73-0.02-7.14c-2.74,0.11-5.53,0.22-8.43,0.33c0.18-0.31,0.3-0.59,0.48-0.8c1.67-1.95,3.27-3.99,5.06-5.82
		c3.08-3.15,6.29-6.17,9.44-9.25C98.98,107.18,99.04,107.14,99.17,107.03z"/>
</g>
<g>
	<path fill="white" d="M13.31,247.95c-3.17,0-6.34,0-9.51,0c-0.14-0.02-0.28-0.04-0.43-0.05c-0.95-0.11-1.92-0.16-2.86-0.34
		c-2.53-0.48-4.51-1.8-5.8-4.06c-0.81-1.41-1.09-2.98-1.23-4.57c-0.04-0.53-0.08-1.07-0.12-1.6c0-3.58,0-7.16,0-10.75
		c0.02-0.15,0.04-0.3,0.05-0.46c0.11-0.94,0.16-1.9,0.33-2.83c0.4-2.21,1.49-4.02,3.32-5.34c1.57-1.13,3.38-1.59,5.28-1.68
		c4.27-0.2,8.55-0.17,12.82-0.04c1.06,0.03,2.13,0.15,3.17,0.37c2.37,0.51,4.27,1.75,5.47,3.92c0.79,1.43,1.17,2.99,1.24,4.6
		c0.2,4.28,0.18,8.57,0.04,12.85c-0.03,1.04-0.13,2.09-0.37,3.1c-0.78,3.4-2.88,5.57-6.3,6.42c-1.29,0.32-2.62,0.37-3.94,0.4
		C14.09,247.9,13.7,247.93,13.31,247.95z M22.25,231.58c0.01,0,0.03,0,0.04,0c0-1.18,0.04-2.36-0.01-3.54
		c-0.06-1.39-0.09-2.8-0.3-4.17c-0.31-2.01-1.41-3.48-3.38-4.2c-1.06-0.39-2.16-0.57-3.28-0.61c-2.2-0.06-4.41-0.09-6.61-0.1
		c-1.57-0.01-3.15,0.01-4.72,0.04c-1.17,0.02-2.34,0.07-3.48,0.37c-1.93,0.5-3.21,1.68-3.8,3.6c-0.42,1.36-0.52,2.76-0.53,4.17
		c-0.02,3.34-0.04,6.69,0,10.03c0.01,1.02,0.11,2.05,0.31,3.05c0.5,2.47,1.98,4.03,4.5,4.48c1.03,0.18,2.09,0.29,3.15,0.31
		c3.02,0.05,6.05,0.07,9.07,0.04c1.38-0.01,2.78-0.1,4.14-0.3c2.43-0.36,4.07-1.91,4.56-4.26c0.2-0.93,0.31-1.88,0.33-2.83
		C22.29,235.64,22.25,233.61,22.25,231.58z"/>
	<path fill="white" d="M9.27,223.88c4.51,0,8.15,3.63,8.16,8.15c0.02,4.51-3.65,8.17-8.17,8.16c-4.51-0.01-8.15-3.65-8.14-8.16
		C1.12,227.52,4.76,223.88,9.27,223.88z M9.27,226.75c-2.93,0-5.29,2.35-5.29,5.28c0,2.93,2.35,5.29,5.28,5.3
		c2.95,0,5.31-2.36,5.31-5.3C14.56,229.09,12.21,226.75,9.27,226.75z"/>
	<path fill="white" d="M17.76,221.66c1.06,0,1.9,0.83,1.9,1.88c0.01,1.07-0.83,1.92-1.9,1.92c-1.05,0-1.9-0.84-1.91-1.89
		C15.85,222.51,16.69,221.66,17.76,221.66z"/>
</g>
<g>
	<path fill="white" d="M36.3,243.5c3.6,0.24,6.83-0.62,9.77-2.84c-3.09-0.28-5.11-1.84-6.26-4.7c1.01,0,1.96,0,2.9,0
		c0.01-0.04,0.01-0.07,0.02-0.11c-3.25-1.02-5-3.21-5.26-6.69c0.98,0.47,1.91,0.8,3.01,0.81c-1.7-1.37-2.75-3.02-2.92-5.15
		c-0.11-1.34,0.25-2.58,0.92-3.79c3.64,4.25,8.17,6.63,13.69,6.99c0-0.75-0.05-1.44,0.01-2.12c0.28-3.48,3.26-6.16,6.76-6.1
		c1.79,0.03,3.32,0.67,4.61,1.91c0.16,0.16,0.3,0.2,0.53,0.15c1.25-0.31,2.45-0.74,3.57-1.36c0.07-0.04,0.14-0.08,0.21-0.11
		c0.02-0.01,0.05,0.01,0.12,0.02c-0.5,1.5-1.43,2.65-2.74,3.51c0.02,0.04,0.04,0.09,0.06,0.13c1.17-0.32,2.33-0.65,3.53-0.98
		c-0.09,0.14-0.18,0.29-0.29,0.43c-0.76,1.06-1.67,1.98-2.71,2.76c-0.23,0.17-0.3,0.34-0.29,0.63c0.24,7.5-4.22,14.89-10.98,17.86
		c-6.09,2.67-12.11,2.31-17.92-1C36.53,243.66,36.42,243.58,36.3,243.5z"/>
</g>
</svg>

  )
}

export default SVGItchioCircleIcon;