import "./style.css";

const Article = (props) => {
    const {title, content, children} = props;

    const style = {
        backgroundColor : props.bgcolor || "white",
    }
    const content_style = {
        textDecoration: props.contentDecoration || "none"
    }

    if(!!title) {
        var bullet = "<span>•</span>";
    }

    return (
        <div className="Article">
            <div className="Container" style={style}>
                <div className="Title">
                    <h1>
                        <span style={{color:'#F01D43', margin: '.5rem'}} dangerouslySetInnerHTML={{__html:bullet}}/>
                        {title}
                        <span style={{color:'#F01D43', margin: '.5rem'}} dangerouslySetInnerHTML={{__html:bullet}}/>
                    </h1>
                </div>
                <div className="Content" style={content_style}>
                    <b>{content}</b>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Article;