import { useState } from "react";

const SVGInstagramHeaderIcon = (props) => {

  const [fillCollor, setFillCollor] = useState(props.fill);

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 200 200" 

    fill={fillCollor} 
    onMouseOver={() => setFillCollor(props.hovercolor)} 
    onMouseOut={() => setFillCollor(props.fill)} 
    style={{width: props.psize, heigth: props.psize}}>

      <g>
        <path d="M123.89,197.76c-19.47,0-38.94,0-58.41,0c-0.88-0.11-1.75-0.22-2.63-0.32c-5.86-0.66-11.8-0.96-17.58-2.06
          c-15.54-2.97-27.7-11.04-35.64-24.95c-4.96-8.69-6.71-18.29-7.54-28.1c-0.28-3.28-0.51-6.56-0.76-9.84c0-22.01,0-44.02,0-66.03
          c0.11-0.93,0.22-1.87,0.33-2.81c0.66-5.8,0.99-11.66,2.03-17.4c2.46-13.57,9.13-24.68,20.41-32.81
          c9.66-6.97,20.75-9.77,32.42-10.31c26.25-1.21,52.52-1.07,78.78-0.27c6.51,0.2,13.1,0.9,19.46,2.25
          c14.59,3.12,26.21,10.76,33.61,24.08c4.88,8.78,7.17,18.34,7.62,28.27c1.21,26.31,1.08,52.64,0.27,78.96
          c-0.2,6.38-0.82,12.86-2.25,19.07c-4.82,20.91-17.71,34.25-38.68,39.43c-7.94,1.96-16.09,2.29-24.23,2.49
          C128.7,197.47,126.3,197.64,123.89,197.76z M178.86,97.22c0.08,0,0.17,0,0.25,0c0-7.25,0.23-14.51-0.06-21.75
          c-0.34-8.56-0.56-17.19-1.84-25.64c-1.88-12.38-8.64-21.38-20.77-25.8c-6.52-2.38-13.3-3.53-20.17-3.72
          c-13.54-0.37-27.08-0.52-40.62-0.61c-9.67-0.06-19.34,0.08-29.01,0.23c-7.19,0.12-14.36,0.44-21.38,2.27
          C33.42,25.31,25.53,32.5,21.93,44.3c-2.55,8.37-3.22,16.99-3.28,25.64c-0.15,20.55-0.22,41.09,0,61.64
          c0.07,6.26,0.67,12.61,1.91,18.74c3.07,15.15,12.19,24.78,27.63,27.53c6.36,1.14,12.87,1.78,19.33,1.89
          c18.57,0.32,37.15,0.44,55.73,0.24c8.5-0.09,17.06-0.59,25.46-1.82c14.91-2.19,24.99-11.75,28.03-26.16
          c1.2-5.69,1.89-11.57,2.03-17.39C179.07,122.16,178.86,109.69,178.86,97.22z"/>
        <path d="M99.09,49.89c27.71-0.01,50.05,22.29,50.14,50.06c0.09,27.72-22.42,50.21-50.2,50.17C71.31,150.07,48.98,127.69,49,99.98
          C49.02,72.26,71.39,49.89,99.09,49.89z M99.1,67.51c-18.01,0-32.49,14.47-32.51,32.47c-0.01,18.01,14.45,32.52,32.44,32.55
          c18.1,0.03,32.65-14.5,32.6-32.57C131.57,81.91,117.13,67.5,99.1,67.51z"/>
        <path d="M151.23,36.22c6.49-0.01,11.64,5.08,11.7,11.56c0.05,6.56-5.13,11.81-11.65,11.82c-6.42,0.01-11.68-5.19-11.72-11.59
          C139.5,41.46,144.69,36.23,151.23,36.22z"/>
      </g>
    </svg>
  )
}

export default SVGInstagramHeaderIcon;