const SVGGuaranaFullLogo = (props) => {

  return (

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 200 200" 

    style={{width: props.psize, heigth: props.psize}}>

    <path fill={props.fill} d="M103.65,85.01V90c0,1.05-0.86,1.91-1.91,1.91h-6.07v6.06c0,1.05-0.85,1.91-1.9,1.91h-5c-1.04,0-1.9-0.86-1.9-1.91v-6.06
      H80.8c-1.04,0-1.9-0.86-1.9-1.91v-4.99c0-1.05,0.86-1.91,1.9-1.91h6.07v-6.07c0-1.04,0.86-1.9,1.9-1.9h5c1.05,0,1.9,0.86,1.9,1.9
      v6.07h6.07C102.79,83.1,103.65,83.96,103.65,85.01z"/>
    <path fill={props.fill} d="M149.83,56c-1.41-0.49-2.84-0.96-4.29-1.39c-1.15-0.34-2.29-0.66-3.45-0.96c-0.03-0.01-0.05-0.02-0.08-0.02
      c-0.01,0-0.02,0-0.03-0.01c-3.93-0.56-12.66-1.41-21.03,0.62c0,0-0.04,0.01-0.11,0.03c-0.43,0.13-2,0.62-2.83,0.96
      c1.5,1.46,2.89,3.03,4.16,4.7c5.54,7.28,8.83,16.35,8.83,26.18c0,13.04-5.8,24.75-14.95,32.69c0.29,0.13,0.58,0.25,0.88,0.37
      c8.88,3.44,19.58,2.55,30.97-2.6c20.87-10.04,25.9-42.13,26.76-49.1C171.34,65.55,161.31,59.98,149.83,56z M141.94,96.14
      c-2.87,2.23-7.01,1.71-9.24-1.16c-2.23-2.87-1.71-7.01,1.16-9.24c2.87-2.23,7.01-1.71,9.24,1.16
      C145.34,89.77,144.82,93.9,141.94,96.14z M159.64,82.39c-2.88,2.23-7.01,1.71-9.24-1.16c-2.23-2.87-1.71-7.01,1.16-9.24
      c2.88-2.23,7.01-1.71,9.25,1.16C163.03,76.02,162.51,80.16,159.64,82.39z"/>
    <path fill={props.fill} d="M52.18,110.82c-3.76-1.3-5.78-2.65-5.94-2.76c-0.35-0.24-0.56-0.63-0.56-1.06c-0.01-0.42,0.19-0.82,0.53-1.07l5.68-4.18
      c-1.35-2.91-2.29-5.95-2.83-9.15c-0.04,0.01-0.1,0.02-0.15,0.02c0-0.01-0.18,0-1.7,0c0,3.16-0.23,11.15-2.3,16.26
      c-0.21,0.51-0.7,0.82-1.21,0.82c-0.17,0-0.33-0.03-0.49-0.1c-0.67-0.27-0.99-1.03-0.72-1.7c1.9-4.63,2.1-12.27,2.11-15.27
      c-2.33,0.02-5.11,0.1-7.84,0.37c-1.29,1.7-5.38,9.48-4.06,13.93c0.21,0.7-0.18,1.42-0.88,1.63c-0.12,0.03-0.24,0.05-0.37,0.05
      c-0.56,0-1.08-0.37-1.24-0.93c-1.36-4.56,1.35-10.87,3.24-14.26c-0.23,0.03-0.45,0.07-0.67,0.11c-7.34,1.3-13.99,4.77-14.06,4.81
      c-0.19,0.1-0.4,0.15-0.6,0.15c-0.47,0-0.93-0.25-1.16-0.7c-0.34-0.64-0.1-1.43,0.55-1.76c0.28-0.15,7.06-3.69,14.81-5.07
      c0.25-0.04,0.51-0.09,0.77-0.13c-5.67-4.57-10.31-6.11-10.36-6.13c-0.69-0.23-1.06-0.96-0.84-1.64c0.23-0.69,0.96-1.06,1.64-0.84
      c0.25,0.09,6.23,2.07,13.08,8.18c2.6-0.24,5.23-0.34,7.48-0.37l-3.85-11.98c-0.23-0.69,0.16-1.42,0.84-1.63
      c0.68-0.23,1.42,0.16,1.63,0.84l4.11,12.76c1.19,0.01,1.68,0,1.87,0c-0.12-1.27-0.18-2.56-0.18-3.89c0-4.27,0.7-8.37,2.02-12.2
      c-2.21-1.39-5.94-6.36-8.45-12.09c-0.28,0.13-0.55,0.27-0.83,0.41c-9.2,4.63-19.11,12.53-25.76,20.03
      c-5.81,6.55-9.01,12.6-8.58,16.2c1.36,11.27,34.6,26.73,52.41,26.73c1.8,0,4.73-0.66,7.18-1.34
      C60.81,120.65,55.9,116.17,52.18,110.82z"/>
    <path fill={props.fill} d="M127.54,86.12c0,12.51-5.79,23.68-14.84,30.99c-6.84,5.52-15.53,8.83-24.98,8.83c-6.02,0-11.72-1.34-16.84-3.74
      c-5.03-2.36-9.49-5.74-13.13-9.87c2.35,0.47,5.04,0.82,8.07,0.92c0.59,0.02,1.19,0.03,1.82,0.03c0.99,0,2.03-0.03,3.12-0.09
      c11.47-0.64,28.09-5.01,48.46-19.95c0.02-0.01,0.03-0.03,0.05-0.03c0.23-0.19,0.39-0.44,0.45-0.72c0,0,0,0,0-0.01
      c0.01-0.04,0.02-0.09,0.03-0.13c0.03-0.3-0.03-0.6-0.19-0.86c-16.81-26.43-43.98-32.95-59-34.46c4.19-3.92,9.22-6.95,14.8-8.77
      c1.27-0.41,2.57-0.76,3.9-1.05c0.94-0.21,1.89-0.37,2.85-0.51c1.83-0.26,3.69-0.39,5.6-0.39c4.25,0,8.35,0.67,12.2,1.91
      c1.57,0.5,3.11,1.11,4.6,1.81c3.15,1.47,6.08,3.34,8.71,5.55c0.98,0.82,1.93,1.7,2.84,2.61C123.15,65.37,127.54,75.24,127.54,86.12z
      "/>
    <path fill={props.fill} d="M52.27,69.7c-0.15,0.3-0.29,0.6-0.43,0.9c-0.45-0.43-1.09-1.14-1.8-2.08h-0.01l-4.75-8.06l-0.01-0.03
      c0.21,0.1,2.22,1.15,4.95,2.96L52.27,69.7z"/>
    <path fill={props.fill} d="M69.52,106.4c-10.77,0.12-14.26-4.01-14.79-4.74c-2.31-4.69-3.6-9.97-3.6-15.54c0-6.68,1.85-12.93,5.09-18.26
      c0.04-0.08,0.09-0.16,0.14-0.23c0.78-0.37,1.97-0.73,3.78-1c1.87-0.28,5.14-0.19,9.26,0.39c-2.5,2.29-4.57,5.1-6.03,8.28v0.01
      c-1.58,3.43-2.47,7.28-2.47,11.33C60.9,94.55,64.25,101.62,69.52,106.4z"/>
    <path fill={props.fill} d="M141.66,49.22c-1.66-12.22-7.44-25-18.81-33.79c-1.18-0.17-2.38-0.24-3.59-0.24c-9.67,0-19.76,5.09-28.25,12.36
      c-0.23,0.2-0.46,0.4-0.69,0.6c4.12,3.42,10.6,9.74,14.48,18.2c2.51,1.08,4.91,2.4,7.15,3.92c0.38-0.79,0.95-1.99,1.28-2.86
      c-0.7-1.4-3.09-5.62-7.13-7.28c-0.67-0.27-0.98-1.03-0.71-1.7c0.27-0.67,1.03-0.99,1.7-0.71c3.5,1.43,5.91,4.34,7.3,6.48
      c0.7-2.05,1.43-4.43,1.91-6.68c0.04-0.23,0.1-0.45,0.14-0.68c-1.86-3.4-8.15-7.55-10.5-8.9c-0.63-0.37-0.84-1.16-0.48-1.78
      c0.36-0.63,1.16-0.84,1.78-0.48c0.3,0.17,6.28,3.65,9.78,7.57c0.69-4.99,0.97-10.21,0.97-10.28c0.03-0.71,0.63-1.27,1.37-1.23
      c0.72,0.03,1.27,0.65,1.23,1.37c-0.02,0.3-0.33,5.91-1.1,11.14c2.61-1.19,7.2-2.71,13.17-2.51c0.72,0.03,1.29,0.63,1.26,1.35
      c-0.03,0.72-0.63,1.29-1.35,1.26c-7.15-0.24-12.15,2.27-13.62,3.11c-0.04,0.2-0.08,0.4-0.12,0.59c-0.53,2.47-1.33,5.06-2.09,7.24
      c2.98-0.85,8.28-2.05,14.52-2.05h0.12c0.72,0.01,1.3,0.59,1.3,1.31c-0.01,0.71-0.59,1.3-1.3,1.3c-0.04-0.01-0.08,0-0.12,0
      c-7.58,0-13.81,1.91-15.63,2.54h-0.01c-0.35,0.91-1,2.53-1.42,3.53c0.24,0.18,0.49,0.38,0.73,0.58c9.96-4.02,21.42-3.18,26.81-2.44
      C141.74,49.79,141.71,49.5,141.66,49.22z M87.72,30.54L87.72,30.54c0.05,0.05,0.1,0.09,0.17,0.13
      C87.83,30.61,87.78,30.57,87.72,30.54z M142.01,53.63c0.03,0,0.05,0.01,0.08,0.02v-0.01C142.06,53.64,142.04,53.63,142.01,53.63z"/>
    <path fill={props.fill} d="M75.33,49.36c1.43-0.48,2.86-0.85,4.25-1.14c-0.03-0.04-0.07-0.09-0.1-0.14c-0.18-0.27-0.24-0.58-0.21-0.88
      c-1.33,0.29-2.63,0.64-3.9,1.05c0.14,0.3,0.15,0.65,0.03,0.98C75.37,49.29,75.36,49.33,75.33,49.36z M104.81,50.96
      c-0.1-0.31-0.19-0.63-0.3-0.94c-1.49-0.7-3.03-1.3-4.6-1.81c-3.84-1.24-7.94-1.91-12.2-1.91c-1.9,0-3.77,0.13-5.6,0.39
      c-0.1,0.49-0.18,0.85-0.31,1.14c8.53-1.25,15.92,0.59,20.31,2.16c2.13,0.76,3.55,1.47,4.05,1.73
      C105.72,51.45,105.27,51.2,104.81,50.96z"/>
    <path fill={props.fill} d="M99.98,44.6c-1.64-0.49-3.33-0.88-5.05-1.16c-2.35-0.4-4.75-0.61-7.21-0.61c-1.58,0-3.14,0.09-4.67,0.25
      c0.67-1.87,1.78-3.93,3.77-5.37c0.58-0.43,0.7-1.24,0.29-1.83c-0.43-0.58-1.24-0.71-1.83-0.29c-3.03,2.2-4.4,5.44-5.09,7.89
      c-2.79,0.49-5.48,1.24-8.06,2.25c-1.58-1.16-3.55-2.67-5.01-4.03c0.3-1.84,1.53-7.58,5.87-11.76c0.52-0.5,0.54-1.32,0.03-1.84
      c-0.5-0.51-1.32-0.53-1.84-0.03c-3.88,3.75-5.55,8.44-6.26,11.33c-2.87-3.24-6.53-7.99-6.57-8.05c-0.44-0.57-1.26-0.68-1.83-0.24
      c-0.57,0.44-0.68,1.26-0.24,1.83c0.2,0.27,4.56,5.92,7.59,9.17c-1.56,1.15-5.35,3.21-12.65,3.21c-0.72,0-1.3,0.58-1.3,1.3
      c0,0.72,0.58,1.3,1.3,1.3c8.38,0,12.72-2.52,14.52-3.96c1.11,1,2.41,2.03,3.62,2.96c-5.07,2.37-9.6,5.7-13.36,9.76
      c-3.83-0.18-6.16-0.03-6.27-0.02c-0.4,0.03-0.76,0.24-0.99,0.57c-0.22,0.33-0.28,0.75-0.16,1.13l1.63,5.02l2.05,6.3
      c-0.15,0.3-0.29,0.6-0.43,0.9c-0.45-0.43-1.09-1.14-1.8-2.08h-0.01l-4.75-8.06l-0.01-0.03l-0.01-0.01l-0.01-0.01
      c-0.01-0.06-0.03-0.1-0.06-0.17c-0.45-1.32-0.83-2.66-1.13-3.99c-3.39-14.72,1.81-29.64,4.73-36.4c1.53,1.89,4.01,3.82,7.95,4.42
      c3.01,0.45,5.89,0.49,8.94,0.52c4.13,0.05,8.39,0.1,13.14,1.26c1.49,0.36,3.23,1.1,5.13,2.15c1.21,0.67,2.48,1.46,3.76,2.35
      c0.06,0.04,0.11,0.08,0.17,0.12C91.19,33.4,96.3,38.24,99.98,44.6z"/>
    <path fill={props.fill} d="M105.65,96.75c-0.01,0.02-0.02,0.03-0.02,0.04c0.22-0.1,0.43-0.22,0.64-0.32C106.07,96.56,105.86,96.65,105.65,96.75z"/>
    <path fill={props.fill} d="M28.89,64.47"/>
    <path fill={props.fill} d="M63.14,97.17"/>
    <path fill={props.fill} d="M109.28,94.89"/>
    <path fill={props.fill} d="M111.8,103.19"/>
    <path fill={props.fill} d="M75.33,49.36c-0.24,0.5-0.78,0.78-1.34,0.7c-0.25-0.03-0.52,0-0.81,0.08C73.9,49.86,74.62,49.6,75.33,49.36z"/>
    <path fill={props.fill} d="M81.81,47.83c-0.12,0.26-0.28,0.45-0.52,0.62c-0.23,0.15-0.48,0.22-0.72,0.22c-0.37,0-0.73-0.16-0.98-0.44
      C80.33,48.07,81.07,47.94,81.81,47.83z"/>
    <path fill={props.fill} d="M41.49,77.64"/>
    <path fill={props.fill} d="M116.18,91.39c-0.99,0.43-2.27,1.1-3.82,1.91l-0.03,0.02l-6.66,3.34c1.39-3.02,2.16-6.43,2.16-10.01
      c0-1.29-0.1-2.54-0.3-3.76c-0.03-0.25-0.08-0.5-0.12-0.76c0-0.01-0.01-0.01-0.01-0.01c-0.09-0.47-0.18-0.94-0.3-1.41l7.47,8.79
      L116.18,91.39z"/>
    <g>
      <path fill={props.fill} d="M25.29,160.43H13.25c-3.19,0-5.55-0.64-7.09-1.91c-1.54-1.27-2.31-3.24-2.31-5.89V140.7c0-2.65,0.77-4.61,2.31-5.89
        c1.54-1.27,3.91-1.91,7.09-1.91h9.56c0.48,0,0.83,0.11,1.05,0.33c0.22,0.22,0.33,0.57,0.33,1.05v4.63c0,0.48-0.11,0.84-0.33,1.05
        c-0.22,0.22-0.57,0.33-1.05,0.33h-7.96c-0.79,0-1.38,0.16-1.76,0.48c-0.38,0.32-0.57,0.8-0.57,1.43v9.14
        c0,0.64,0.15,1.11,0.44,1.42c0.29,0.31,0.73,0.46,1.32,0.46h3.71v-5.97c0-0.48,0.11-0.83,0.33-1.05c0.22-0.22,0.57-0.33,1.05-0.33
        h5.93c0.48,0,0.83,0.11,1.05,0.33c0.22,0.22,0.33,0.57,0.33,1.05v11.82c0,0.48-0.11,0.84-0.33,1.05
        C26.13,160.33,25.78,160.43,25.29,160.43z"/>
      <path fill={props.fill} d="M54.78,153.2c0,1.17-0.17,2.24-0.52,3.19c-0.34,0.96-0.96,1.77-1.84,2.45c-0.88,0.68-2.08,1.2-3.61,1.59
        c-1.53,0.38-3.48,0.57-5.85,0.57s-4.32-0.19-5.85-0.57c-1.53-0.38-2.73-0.91-3.61-1.59c-0.88-0.68-1.49-1.49-1.84-2.45
        c-0.34-0.96-0.52-2.02-0.52-3.19v-18.93c0-0.48,0.11-0.83,0.33-1.05c0.22-0.22,0.57-0.33,1.05-0.33h6c0.48,0,0.83,0.11,1.05,0.33
        c0.22,0.22,0.33,0.57,0.33,1.05v17.44c0,0.51,0.19,0.94,0.57,1.28c0.38,0.34,1.24,0.52,2.56,0.52c1.35,0,2.22-0.17,2.6-0.52
        c0.38-0.34,0.57-0.77,0.57-1.28v-17.44c0-0.48,0.11-0.83,0.33-1.05c0.22-0.22,0.57-0.33,1.05-0.33h5.81c0.48,0,0.83,0.11,1.05,0.33
        c0.22,0.22,0.33,0.57,0.33,1.05V153.2z"/>
      <path fill={props.fill} d="M67.67,159.06c0,0.48-0.11,0.84-0.33,1.05c-0.22,0.22-0.57,0.33-1.05,0.33h-5.62c-0.48,0-0.84-0.11-1.05-0.33
        c-0.22-0.22-0.33-0.57-0.33-1.05v-10.1c0-0.76,0.12-1.64,0.36-2.64c0.24-0.99,0.62-2.15,1.13-3.48l3.33-8.68
        c0.15-0.43,0.39-0.75,0.71-0.96c0.32-0.2,0.73-0.31,1.24-0.31h10.29c0.48,0,0.89,0.1,1.2,0.31c0.32,0.2,0.56,0.52,0.71,0.96
        l3.33,8.68c0.51,1.33,0.88,2.49,1.13,3.48c0.24,0.99,0.36,1.87,0.36,2.64v10.1c0,0.48-0.11,0.84-0.32,1.05
        c-0.22,0.22-0.57,0.33-1.05,0.33h-5.77c-0.51,0-0.89-0.11-1.13-0.33c-0.24-0.22-0.36-0.57-0.36-1.05v-5.35h-6.77V159.06z
        M69.85,140.93l-1.8,5.81h6l-1.8-5.81c-0.1-0.23-0.21-0.4-0.33-0.5c-0.11-0.1-0.25-0.15-0.4-0.15h-0.96c-0.15,0-0.29,0.05-0.4,0.15
        C70.06,140.53,69.95,140.7,69.85,140.93z"/>
      <path fill={props.fill} d="M95.06,160.43h-5.89c-0.48,0-0.84-0.11-1.05-0.33c-0.22-0.22-0.33-0.57-0.33-1.05v-24.78c0-0.48,0.11-0.83,0.33-1.05
        c0.22-0.22,0.57-0.33,1.05-0.33h12.74c2.85,0,4.83,0.59,5.91,1.78c1.08,1.19,1.63,2.72,1.63,4.61v1.76c0,1.07-0.16,1.98-0.48,2.73
        c-0.32,0.75-0.87,1.29-1.66,1.63c1.38,0.15,2.49,0.71,3.35,1.68c0.85,0.97,1.28,2.26,1.28,3.86v8.11c0,0.48-0.11,0.84-0.33,1.05
        c-0.22,0.22-0.57,0.33-1.05,0.33h-5.93c-0.48,0-0.84-0.11-1.05-0.33c-0.22-0.22-0.33-0.57-0.33-1.05v-5.85
        c0-0.56-0.11-0.98-0.32-1.24c-0.22-0.27-0.59-0.4-1.13-0.4h-5.35v7.5c0,0.48-0.11,0.84-0.33,1.05
        C95.89,160.33,95.54,160.43,95.06,160.43z M96.43,139.78v4.9h2.87c0.66,0,1.11-0.17,1.34-0.52c0.23-0.34,0.34-0.77,0.34-1.28v-1.3
        c0-0.51-0.11-0.94-0.34-1.28c-0.23-0.34-0.68-0.52-1.34-0.52H96.43z"/>
      <path fill={props.fill} d="M124.01,159.06c0,0.48-0.11,0.84-0.33,1.05c-0.22,0.22-0.57,0.33-1.05,0.33h-5.62c-0.48,0-0.84-0.11-1.05-0.33
        c-0.22-0.22-0.33-0.57-0.33-1.05v-10.1c0-0.76,0.12-1.64,0.36-2.64c0.24-0.99,0.62-2.15,1.13-3.48l3.33-8.68
        c0.15-0.43,0.39-0.75,0.71-0.96c0.32-0.2,0.73-0.31,1.24-0.31h10.29c0.48,0,0.89,0.1,1.2,0.31c0.32,0.2,0.56,0.52,0.71,0.96
        l3.33,8.68c0.51,1.33,0.89,2.49,1.13,3.48c0.24,0.99,0.36,1.87,0.36,2.64v10.1c0,0.48-0.11,0.84-0.33,1.05
        c-0.22,0.22-0.57,0.33-1.05,0.33h-5.78c-0.51,0-0.89-0.11-1.13-0.33c-0.24-0.22-0.36-0.57-0.36-1.05v-5.35h-6.77V159.06z
        M126.19,140.93l-1.8,5.81h6l-1.8-5.81c-0.1-0.23-0.21-0.4-0.33-0.5c-0.11-0.1-0.25-0.15-0.4-0.15h-0.96
        c-0.15,0-0.29,0.05-0.4,0.15C126.4,140.53,126.29,140.7,126.19,140.93z"/>
      <path fill={props.fill} d="M151.24,160.43h-5.74c-0.48,0-0.84-0.11-1.05-0.33c-0.22-0.22-0.33-0.57-0.33-1.05v-24.78c0-0.48,0.11-0.83,0.33-1.05
        c0.22-0.22,0.57-0.33,1.05-0.33h3.98c0.48,0,0.9,0.08,1.24,0.23c0.34,0.15,0.68,0.42,1.01,0.8l7.42,8.49v-8.15
        c0-0.48,0.11-0.83,0.33-1.05c0.22-0.22,0.57-0.33,1.05-0.33h5.74c0.48,0,0.84,0.11,1.05,0.33c0.22,0.22,0.33,0.57,0.33,1.05v24.78
        c0,0.48-0.11,0.84-0.33,1.05c-0.22,0.22-0.57,0.33-1.05,0.33h-5.74c-0.48,0-0.84-0.11-1.05-0.33c-0.22-0.22-0.33-0.57-0.33-1.05
        v-4.7l-6.54-7.92v12.62c0,0.48-0.11,0.84-0.33,1.05C152.07,160.33,151.72,160.43,151.24,160.43z"/>
      <path fill={props.fill} d="M180.73,159.06c0,0.48-0.11,0.84-0.33,1.05c-0.22,0.22-0.57,0.33-1.05,0.33h-5.62c-0.48,0-0.84-0.11-1.05-0.33
        c-0.22-0.22-0.33-0.57-0.33-1.05v-10.1c0-0.76,0.12-1.64,0.36-2.64c0.24-0.99,0.62-2.15,1.13-3.48l3.33-8.68
        c0.15-0.43,0.39-0.75,0.71-0.96c0.32-0.2,0.73-0.31,1.24-0.31h10.29c0.48,0,0.89,0.1,1.2,0.31c0.32,0.2,0.56,0.52,0.71,0.96
        l3.33,8.68c0.51,1.33,0.89,2.49,1.13,3.48c0.24,0.99,0.36,1.87,0.36,2.64v10.1c0,0.48-0.11,0.84-0.33,1.05
        c-0.22,0.22-0.57,0.33-1.05,0.33h-5.78c-0.51,0-0.89-0.11-1.13-0.33c-0.24-0.22-0.36-0.57-0.36-1.05v-5.35h-6.77V159.06z
        M190.86,129.99l-13.35,1.64c-0.43,0.05-0.76-0.04-0.98-0.27c-0.22-0.23-0.33-0.56-0.33-0.99v-3.67c0-0.48,0.11-0.82,0.33-1.01
        c0.22-0.19,0.57-0.31,1.05-0.36l13.35-1.64c0.43-0.05,0.76,0.04,0.98,0.27c0.22,0.23,0.33,0.56,0.33,0.99v3.67
        c0,0.48-0.11,0.82-0.33,1.01C191.7,129.82,191.35,129.94,190.86,129.99z M182.91,140.93l-1.8,5.81h6l-1.8-5.81
        c-0.1-0.23-0.21-0.4-0.33-0.5c-0.11-0.1-0.25-0.15-0.4-0.15h-0.96c-0.15,0-0.29,0.05-0.4,0.15
        C183.12,140.53,183.01,140.7,182.91,140.93z"/>
    </g>
    <g>
      <path fill={props.fill} d="M44.99,183.91c-1.14-0.35-2.04-0.81-2.7-1.38l1.02-2.28c0.65,0.51,1.44,0.93,2.38,1.25c0.94,0.32,1.89,0.49,2.84,0.49
        c1.18,0,2.05-0.19,2.63-0.57c0.58-0.38,0.87-0.87,0.87-1.49c0-0.45-0.16-0.83-0.49-1.12c-0.32-0.29-0.73-0.52-1.23-0.69
        c-0.5-0.17-1.18-0.35-2.03-0.56c-1.21-0.29-2.18-0.57-2.93-0.86c-0.75-0.29-1.39-0.73-1.92-1.34c-0.54-0.61-0.8-1.44-0.8-2.48
        c0-0.87,0.24-1.67,0.71-2.38c0.47-0.72,1.19-1.28,2.15-1.71c0.96-0.42,2.13-0.63,3.51-0.63c0.96,0,1.91,0.12,2.85,0.36
        c0.93,0.24,1.74,0.59,2.42,1.04l-0.93,2.28c-0.69-0.41-1.42-0.72-2.17-0.93c-0.75-0.21-1.48-0.32-2.19-0.32
        c-1.16,0-2.02,0.2-2.59,0.59c-0.56,0.39-0.85,0.91-0.85,1.56c0,0.45,0.16,0.82,0.49,1.11c0.32,0.29,0.73,0.51,1.23,0.68
        c0.5,0.17,1.18,0.35,2.03,0.57c1.17,0.27,2.14,0.55,2.89,0.85c0.75,0.29,1.4,0.74,1.93,1.34c0.53,0.6,0.8,1.42,0.8,2.44
        c0,0.87-0.24,1.67-0.71,2.37c-0.47,0.71-1.19,1.27-2.16,1.7c-0.96,0.42-2.14,0.63-3.53,0.63C47.3,184.43,46.12,184.26,44.99,183.91
        z"/>
      <path fill={props.fill} d="M65.34,170.87H60.1v-2.49h13.42v2.49h-5.24v13.33h-2.94V170.87z"/>
      <path fill={props.fill} d="M81.66,182.59c-1.22-1.23-1.83-2.99-1.83-5.28v-8.93h2.94v8.81c0,3.1,1.34,4.66,4.02,4.66c2.67,0,4-1.55,4-4.66v-8.81h2.89
        v8.93c0,2.29-0.61,4.05-1.82,5.28c-1.21,1.23-2.91,1.84-5.1,1.84C84.58,184.43,82.88,183.82,81.66,182.59z"/>
      <path fill={props.fill} d="M102.31,168.39h6.92c1.69,0,3.19,0.33,4.5,0.98c1.31,0.66,2.33,1.58,3.05,2.78s1.08,2.58,1.08,4.15s-0.36,2.95-1.08,4.15
        c-0.72,1.2-1.74,2.12-3.05,2.78c-1.31,0.66-2.81,0.98-4.5,0.98h-6.92V168.39z M109.09,181.72c1.16,0,2.18-0.22,3.06-0.67
        c0.88-0.44,1.56-1.08,2.03-1.9c0.47-0.82,0.71-1.77,0.71-2.86s-0.24-2.04-0.71-2.86c-0.47-0.82-1.15-1.45-2.03-1.9
        c-0.88-0.44-1.9-0.67-3.06-0.67h-3.84v10.85H109.09z"/>
      <path fill={props.fill} d="M125.5,168.39h2.94v15.82h-2.94V168.39z"/>
      <path fill={props.fill} d="M140.26,183.38c-1.3-0.7-2.31-1.67-3.05-2.92c-0.74-1.24-1.11-2.63-1.11-4.17c0-1.54,0.37-2.93,1.11-4.17
        c0.74-1.24,1.76-2.21,3.05-2.92c1.3-0.7,2.75-1.05,4.36-1.05c1.61,0,3.07,0.35,4.36,1.05c1.3,0.7,2.31,1.67,3.05,2.9
        c0.74,1.24,1.11,2.63,1.11,4.18s-0.37,2.95-1.11,4.18c-0.74,1.24-1.76,2.2-3.05,2.9c-1.3,0.7-2.75,1.05-4.36,1.05
        C143.01,184.43,141.55,184.08,140.26,183.38z M147.47,181.14c0.84-0.47,1.51-1.14,1.99-1.99s0.72-1.8,0.72-2.86
        c0-1.06-0.24-2.01-0.72-2.86c-0.48-0.85-1.15-1.51-1.99-1.99s-1.79-0.71-2.85-0.71c-1.06,0-2,0.24-2.85,0.71s-1.51,1.14-1.99,1.99
        c-0.48,0.85-0.72,1.8-0.72,2.86c0,1.05,0.24,2.01,0.72,2.86s1.15,1.51,1.99,1.99c0.84,0.48,1.79,0.71,2.85,0.71
        C145.67,181.86,146.62,181.62,147.47,181.14z"/>
    </g>
    </svg>

  )
}

export default SVGGuaranaFullLogo;