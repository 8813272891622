import { useState } from "react";

const SVGInstagramCircleIcon = (props) => {

  const [fillCollor, setFillCollor] = useState(props.fill);

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 200 200" 
    
    onMouseOver={() => setFillCollor(props.hovercolor)} 
    onMouseOut={() => setFillCollor(props.fill)} 
    style={{width: props.psize, heigth: props.psize}}>

    <circle fill={fillCollor} cx="100" cy="99.8" r="100"/>
    <g>
        <path fill="white" d="M115.67,165.29c-13,0-26.01,0-39.01,0c-0.58-0.07-1.17-0.15-1.75-0.21c-3.92-0.44-7.88-0.64-11.74-1.38
            c-10.38-1.98-18.5-7.38-23.8-16.66c-3.31-5.8-4.48-12.21-5.03-18.77c-0.18-2.19-0.34-4.38-0.51-6.57c0-14.7,0-29.4,0-44.1
            c0.07-0.62,0.15-1.25,0.22-1.87c0.44-3.88,0.66-7.79,1.35-11.62c1.65-9.06,6.1-16.48,13.63-21.91c6.45-4.65,13.86-6.53,21.65-6.88
            c17.53-0.81,35.08-0.72,52.61-0.18c4.35,0.13,8.75,0.6,12.99,1.51c9.74,2.08,17.51,7.19,22.45,16.08
            c3.26,5.87,4.79,12.25,5.09,18.88c0.81,17.57,0.72,35.16,0.18,52.73c-0.13,4.26-0.55,8.59-1.5,12.74
            c-3.22,13.96-11.83,22.88-25.83,26.34c-5.31,1.31-10.75,1.53-16.19,1.66C118.88,165.1,117.27,165.21,115.67,165.29z M152.38,98.15
            c0.06,0,0.11,0,0.17,0c0-4.84,0.15-9.69-0.04-14.53c-0.23-5.72-0.37-11.48-1.23-17.13c-1.26-8.27-5.77-14.28-13.87-17.23
            c-4.35-1.59-8.88-2.36-13.47-2.48c-9.04-0.25-18.09-0.35-27.13-0.41c-6.46-0.04-12.92,0.05-19.37,0.16
            c-4.8,0.08-9.59,0.29-14.28,1.52c-7.91,2.07-13.18,6.88-15.58,14.76c-1.7,5.59-2.15,11.35-2.19,17.12
            c-0.1,13.72-0.14,27.44,0,41.17c0.04,4.18,0.45,8.42,1.28,12.52c2.05,10.12,8.14,16.55,18.46,18.39c4.24,0.76,8.6,1.19,12.91,1.26
            c12.4,0.21,24.81,0.29,37.22,0.16c5.67-0.06,11.39-0.39,17-1.22c9.96-1.46,16.69-7.85,18.72-17.47c0.8-3.8,1.26-7.73,1.36-11.61
            C152.52,114.8,152.38,106.47,152.38,98.15z"/>
        <path fill="white" d="M99.1,66.53c18.51,0,33.43,14.89,33.49,33.43c0.06,18.51-14.97,33.54-33.53,33.51
            c-18.52-0.03-33.43-14.97-33.41-33.48C65.66,81.47,80.6,66.54,99.1,66.53z M99.11,78.3c-12.03,0-21.7,9.66-21.71,21.68
            c-0.01,12.03,9.65,21.72,21.66,21.74c12.09,0.02,21.8-9.69,21.77-21.76C120.79,87.92,111.15,78.3,99.11,78.3z"/>
        <path fill="white" d="M133.92,57.4c4.34-0.01,7.78,3.39,7.81,7.72c0.03,4.38-3.42,7.89-7.78,7.89c-4.29,0.01-7.8-3.47-7.83-7.74
            C126.09,60.9,129.55,57.41,133.92,57.4z"/>
    </g>
    </svg>

  )
}

export default SVGInstagramCircleIcon;