import "./style.css";
import SVGItchioCircleIcon from "../Utils/SVG/Article/AboutUs/SVGItchioCircleIcon";
import SVGInstagramCircleIcon from "../Utils/SVG/Article/AboutUs/SVGInstagramCircleIcon";
import SVGTwitterCircleIcon from "../Utils/SVG/Article/AboutUs/SVGTwitterCircleIcon";
import SVGGuaranaFullLogo from "../Utils/SVG/Footer/SVGGuaranaFullLogo";

const Footer = () => {

    return (
        <div className="Footer">
            <div className="u-Footer">
                <SVGGuaranaFullLogo fill="white" psize="5rem"/>
                <ul>
                    {/* <li><img src={white_steam} alt="Steam" className="Icon"/></li>                     */}
                    <li>                        
                        <a href = "http://guarana_studio.itch.io/" rel="noreferrer" target="_blank">                         
                            <SVGItchioCircleIcon fill="#F01D43" psize="2.2rem" hovercolor="#F01D43"/>
                        </a>
                    </li>                    
                    <li>
                        <a href = "https://www.instagram.com/guaranagames/" rel="noreferrer" target="_blank">                    
                            <SVGInstagramCircleIcon fill="#F01D43" psize="2.2rem" hovercolor="#F01D43"/>
                        </a>            
                    </li>        
                    <li>
                        <a href = "https://twitter.com/guaranaGame" rel="noreferrer" target="_blank">                    
                            <SVGTwitterCircleIcon fill="#F01D43" psize="2.2rem" hovercolor="#F01D43"/>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>                        
                        <a href = "/terms" rel="noreferrer" target="_blank" className="Terms">
                            <span>Terms and Conditions</span>
                        </a>                        
                    </li>
                    <li><span>|</span></li>
                    <li>
                        <a href = "/copyright" rel="noreferrer" target="_blank" className="Terms">
                            <span>Privacy policy</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="d-Footer">
                <span>&copy; 2022 Guaraná Studio, all rights reserved.</span>
            </div>
        </div>
    );
}

export default Footer;