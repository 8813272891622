import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Article from '../../components/Article';
import ImageCarousel from '../../components/ImageCarousel';
import SVGInstagramCircleIcon from '../../components/Utils/SVG/Article/AboutUs/SVGInstagramCircleIcon';
import SVGTwitterCircleIcon from '../../components/Utils/SVG/Article/AboutUs/SVGTwitterCircleIcon';
import SVGItchioCircleIcon from '../../components/Utils/SVG/Article/AboutUs/SVGItchioCircleIcon';

const AboutUs = () => {
    const images = [
        { src: '/resources/images/article/AboutUs/Super-cat.png', desc: "super-cat-jump" }
    ];

    return (
        <>
            <Header />
            {/* <ImageCarousel interval={5000} images={images} /> */}
            {images.map((image, index) => (
                <img key={index} src={image.src} alt={image.desc} style={{ width: '100%', height: 'auto' }} />
            ))}
            <div id="aboutUs">
                <Article
                    title="About Us"
                    content="Welcome to Guarana Games, your go-to destination for simple and enjoyable indie games. Founded by two passionate Brazilians, Guarana Games is on a mission to create captivating gaming experiences that transcend boundaries and bring joy to players worldwide. Our focus is on delivering indie games with straightforward, engaging gameplay that leaves a lasting impression.

                    At Guarana Games, we believe in the magic of play, and we infuse our creations with a unique blend of creativity, innovation, and a touch of Brazilian flair. Our games are like the Guarana berry - a burst of vibrant energy in the world of gaming.

                    Join us on this exciting journey as we continue to explore new realms, challenge the status quo, and redefine indie gaming. Guarana Games invites you to be part of our growing community, where each player is not just a gamer but a fellow explorer in the boundless universe of interactive entertainment."
                    bgcolor="#FEE7EB"
                />
            </div>
            <div id="contact">
                <Article title='Contact'>
                    <p>
                        For inquiries and collaboration, feel free to contact us at:
                    </p>
                    <a href="mailto:guaranagamestudio@gmail.com" style={{ textDecoration: "none", color: "black" }}>
                        <b>guaranagamestudio@gmail.com</b>
                    </a>
                    <br />
                    <a href="mailto:rafhaelmbraga@gmail.com" style={{ textDecoration: "none", color: "black" }}>
                        <b>rafhaelmbraga@gmail.com</b>
                    </a>
                    <br />
                    <a href="mailto:camilaborges.art@gmail.com" style={{ textDecoration: "none", color: "black" }}>
                        <b>camilaborges.art@gmail.com</b>
                    </a>
                    <br />
                    <br />
                    <ul className='a-Icons'>
                        <li>
                            <a href="http://guarana_studio.itch.io/" rel="noreferrer" target="_blank">
                                <SVGItchioCircleIcon fill="#F01D43" psize="30px" hovercolor="#1DF043" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/guaranagames/" rel="noreferrer" target="_blank">
                                <SVGInstagramCircleIcon fill="#F01D43" psize="30px" hovercolor="#1DF043" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/guaranaGame" rel="noreferrer" target="_blank">
                                <SVGTwitterCircleIcon fill="#F01D43" psize="30px" hovercolor="#1DF043" />
                            </a>
                        </li>
                    </ul>
                </Article>
            </div>
            <Footer />
        </>
    );
}

export default AboutUs;
