import "./style.css";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Dropdown = ({ options, icons, onSelect }) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleSelection = (option) => {
        // onSelect(option);
        if (typeof option !== "object")
            setIsOpen(!isOpen);
    }

    const handleIconSelection = (option) => {
        // onSelect(option);
        if (typeof option !== "object")
            setIsOpen(!isOpen);
    }

    return (<div className="c-Dropdown">
        <button onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? <MenuIcon/> : <CloseIcon/>}
        </button>
        
        {isOpen && (
            <div className="DropdownOptions">
                <ul>
                    {options.map((option) => {
                        return <li key={option.id} className="Item" onClick={() => handleSelection(option.id)}>
                            <a href={option.id}>
                                <b>
                                    {option.opt}
                                </b>
                            </a>
                        </li>
                    })}
                    <ul className="Icons">
                        {icons && icons.map((icon) => {
                            return <li key={icon.id} onClick={() => handleIconSelection(icon.id)}>
                                <a href={icon.id} rel="noreferrer" target="_blank">
                                    {icon.opt}
                                </a>
                            </li>
                        })}
                    </ul>

                </ul>
            </div>
        )}
    </div>
    );

};

export default Dropdown;