import { useState } from "react";

const SVGTwitterHeaderIcon = (props) => {

  const [fillCollor, setFillCollor] = useState(props.fill);

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 200 200" 

    fill={fillCollor} 
    onMouseOver={() => setFillCollor(props.hovercolor)} 
    onMouseOut={() => setFillCollor(props.fill)} 
    style={{width: props.psize, heigth: props.psize}}>

    <g>
      <path d="M75.87,233.25c0-8.66,7.03-15.69,15.69-15.69c8.67,0,15.71,7.04,15.7,15.71c-0.01,8.66-7.05,15.68-15.7,15.67
        C82.89,248.94,75.87,241.91,75.87,233.25z M80.72,233.01c-0.02,0.06-0.03,0.08-0.03,0.1c0,1.62-0.01,3.24,0,4.86
        c0,0.09,0.11,0.22,0.2,0.25c0.73,0.31,1.47,0.6,2.21,0.88c0.25,0.1,0.39,0.23,0.48,0.5c0.57,1.83,2.32,3,4.24,2.86
        c1.9-0.13,3.42-1.55,3.76-3.47c0.02-0.14,0.15-0.3,0.27-0.38c1.54-1.13,3.1-2.24,4.64-3.37c0.27-0.2,0.53-0.33,0.88-0.32
        c0.25,0.01,0.5-0.06,0.74-0.11c2.7-0.57,4.58-3.15,4.29-5.88c-0.26-2.53-2.22-4.5-4.82-4.87c-3.13-0.44-6.07,2-6.22,5.15
        c-0.01,0.14-0.05,0.29-0.13,0.4c-1.05,1.5-2.1,2.99-3.15,4.49c-0.12,0.17-0.25,0.2-0.44,0.21c-0.49,0.04-1.03,0-1.44,0.22
        c-0.68,0.36-1.24,0.2-1.87-0.07C83.16,233.95,81.95,233.5,80.72,233.01z"/>
      <path d="M96.88,233.15c-2.07-0.02-3.71-1.67-3.68-3.72c0.02-2.01,1.72-3.62,3.78-3.59c2.04,0.03,3.65,1.69,3.63,3.74
        C100.58,231.56,98.9,233.17,96.88,233.15z M96.92,231.69c1.22,0,2.21-1,2.2-2.22c-0.01-1.21-1-2.18-2.21-2.18
        c-1.21,0.01-2.19,1-2.19,2.2C94.72,230.7,95.71,231.69,96.92,231.69z"/>
      <path d="M84.87,239.82c0.57,0.22,1.08,0.44,1.6,0.62c1.02,0.35,2.13-0.04,2.74-0.92c0.59-0.86,0.57-2.03-0.12-2.83
        c-0.26-0.3-0.64-0.53-1-0.73c-0.35-0.2-0.75-0.31-1.14-0.47c0.96-0.49,2.69,0.36,3.28,1.6c0.73,1.52,0.08,3.35-1.44,4.06
        C87.35,241.83,85.49,241.22,84.87,239.82z"/>
    </g>
    <g>
      <path d="M132.48,218.63c2.87,0,5.73,0,8.6,0c0.04,0.01,0.08,0.02,0.11,0.02c2.55,0.05,5.1,0.12,7.65,0.15
        c0.43,0.01,0.77,0.13,1.1,0.39c1.02,0.83,1.92,1.78,2.75,2.8c0.5,0.62,1,1.24,1.28,2c0,0.63,0,1.26,0,1.89
        c-0.01,0.02-0.02,0.03-0.02,0.05c-0.12,0.97-0.59,1.75-1.32,2.38c-0.52,0.46-1.12,0.78-1.82,0.83c-1.78,0.13-3.41-1.15-3.72-2.91
        c-0.02-0.09-0.03-0.17-0.05-0.26c-0.19,1.67-1.5,3-3.16,3.16c-0.75,0.07-1.46-0.08-2.11-0.48c-1-0.62-1.54-1.55-1.69-2.71
        c-0.21,1.84-1.84,3.2-3.61,3.2c-1.77,0-3.41-1.36-3.6-3.17c-0.37,1.88-1.43,3.04-3.45,3.16c-2.02-0.12-3.12-1.25-3.51-3.16
        c0,0.01-0.01,0.03-0.01,0.04c-0.11,0.87-0.49,1.59-1.13,2.18c-1.03,0.95-2.7,1.39-4.14,0.34c-0.92-0.67-1.52-1.55-1.63-2.71
        c-0.05-0.49,0-0.98-0.02-1.47c-0.02-0.35,0.08-0.65,0.25-0.94c0.07-0.11,0.13-0.23,0.2-0.34c0.56-0.84,1.21-1.61,1.9-2.34
        c0.62-0.66,1.27-1.3,2.02-1.82c0.12-0.08,0.24-0.13,0.39-0.14c0.53-0.02,1.06-0.05,1.59-0.06c2.14-0.04,4.28-0.06,6.43-0.1
        C131.99,218.65,132.24,218.64,132.48,218.63z"/>
      <path d="M125.82,228.32c0.81,1.29,1.98,1.99,3.51,1.98c1.53,0,2.68-0.73,3.5-2.01c0.85,1.32,2.05,2.01,3.63,2.01
        c1.59,0,2.79-0.68,3.64-2.01c0.82,1.27,1.97,2,3.5,2c1.53,0,2.7-0.69,3.51-1.98c0.96,1.35,2.21,2.1,3.94,1.96
        c0.01,0.11,0.03,0.26,0.04,0.4c0.06,1.02,0.16,2.05,0.17,3.07c0.03,2.51,0.04,5.02,0.03,7.54c-0.01,1.46-0.08,2.92-0.29,4.37
        c-0.13,0.9-0.29,1.8-0.67,2.64c-0.12,0.26-0.29,0.5-0.45,0.74c-0.04,0.07-0.14,0.12-0.23,0.13c-1.02,0.16-2.04,0.36-3.06,0.47
        c-1.3,0.14-2.61,0.25-3.92,0.31c-1.91,0.08-3.83,0.14-5.74,0.15c-1.78,0.01-3.57-0.02-5.35-0.09c-1.65-0.07-3.31-0.2-4.96-0.34
        c-1.13-0.09-2.25-0.26-3.35-0.5c-0.12-0.02-0.2-0.08-0.27-0.17c-0.35-0.44-0.55-0.96-0.71-1.49c-0.28-0.95-0.41-1.92-0.5-2.89
        c-0.24-2.51-0.18-5.02-0.17-7.53c0.01-1.35,0.02-2.7,0.06-4.05c0.03-0.9,0.11-1.79,0.18-2.69c0-0.04,0.01-0.07,0.02-0.08
        C123.6,230.41,124.86,229.67,125.82,228.32z M131.03,242.28c3.63,0.53,7.26,0.53,10.9,0c0.01,0.08,0.01,0.15,0.02,0.22
        c0,0.07,0,0.15,0.01,0.22c0.25,1.78,1.23,2.99,2.9,3.64c0.96,0.37,1.96,0.38,2.97,0.25c0.33-0.04,0.65-0.15,0.92-0.36
        c0.42-0.33,0.54-0.8,0.58-1.3c0.05-0.81-0.1-1.6-0.31-2.37c-0.51-1.85-1.03-3.7-1.54-5.55c-0.3-1.11-0.74-2.15-1.39-3.11
        c-0.34-0.5-0.74-0.94-1.24-1.28c-0.56-0.39-1.18-0.59-1.87-0.58c-0.56,0.01-1.12,0.01-1.66,0.19c-0.66,0.21-1.19,0.62-1.67,1.11
        c-0.04,0.05-0.12,0.09-0.18,0.08c-0.58-0.03-1.16-0.09-1.74-0.12c-1.41-0.06-2.82-0.03-4.22,0.12c-0.1,0.01-0.16-0.01-0.23-0.08
        c-0.34-0.37-0.73-0.69-1.18-0.92c-0.54-0.28-1.13-0.33-1.73-0.38c-1.28-0.1-2.29,0.38-3.11,1.33c-0.76,0.89-1.29,1.91-1.62,3.02
        c-0.6,2.02-1.15,4.06-1.7,6.1c-0.22,0.8-0.38,1.62-0.33,2.46c0.05,0.93,0.51,1.48,1.42,1.63c0.95,0.16,1.91,0.15,2.84-0.16
        c1.62-0.53,2.66-1.62,3.05-3.3C130.98,242.86,130.99,242.57,131.03,242.28z"/>
      <path d="M136.48,235.41c1.28,1.24,2.6,2.42,3.67,3.84c-0.01,0.01-0.02,0.03-0.03,0.04c-0.67-0.03-1.33-0.05-2.01-0.08
        c0,0.15,0,0.28,0,0.41c0,0.41-0.01,0.82,0,1.22c0.01,0.15-0.05,0.2-0.19,0.2c-0.13,0-0.26,0.01-0.39,0.01c-0.71,0-1.42,0-2.13,0
        c-0.16,0-0.32-0.01-0.48-0.02c-0.03,0-0.09-0.05-0.09-0.08c-0.01-0.57,0-1.14,0-1.72c-0.66,0.03-1.34,0.05-2.03,0.08
        c0.04-0.08,0.07-0.14,0.12-0.19c0.4-0.47,0.79-0.96,1.22-1.41c0.74-0.76,1.52-1.49,2.28-2.23
        C136.43,235.45,136.45,235.44,136.48,235.41z"/>
    </g>
    <g>
      <path d="M18.34,246.7c-2.91,0-5.83,0-8.74,0c-0.13-0.02-0.26-0.03-0.39-0.05c-0.88-0.1-1.77-0.14-2.63-0.31
        c-2.33-0.44-4.15-1.65-5.33-3.73c-0.74-1.3-1-2.74-1.13-4.21c-0.04-0.49-0.08-0.98-0.11-1.47c0-3.29,0-6.59,0-9.88
        c0.02-0.14,0.03-0.28,0.05-0.42c0.1-0.87,0.15-1.75,0.3-2.6c0.37-2.03,1.37-3.69,3.06-4.91c1.45-1.04,3.11-1.46,4.85-1.54
        c3.93-0.18,7.86-0.16,11.79-0.04c0.97,0.03,1.96,0.13,2.91,0.34c2.18,0.47,3.92,1.61,5.03,3.6c0.73,1.31,1.07,2.75,1.14,4.23
        c0.18,3.94,0.16,7.88,0.04,11.82c-0.03,0.96-0.12,1.93-0.34,2.85c-0.72,3.13-2.65,5.13-5.79,5.9c-1.19,0.29-2.41,0.34-3.63,0.37
        C19.06,246.66,18.7,246.68,18.34,246.7z M26.57,231.65c0.01,0,0.03,0,0.04,0c0-1.09,0.03-2.17-0.01-3.26
        c-0.05-1.28-0.08-2.57-0.28-3.84c-0.28-1.85-1.29-3.2-3.11-3.86c-0.98-0.36-1.99-0.53-3.02-0.56c-2.03-0.06-4.05-0.08-6.08-0.09
        c-1.45-0.01-2.9,0.01-4.34,0.03c-1.08,0.02-2.15,0.07-3.2,0.34c-1.77,0.46-2.95,1.54-3.49,3.31c-0.38,1.25-0.48,2.54-0.49,3.84
        c-0.02,3.08-0.03,6.15,0,9.23c0.01,0.94,0.1,1.89,0.29,2.81c0.46,2.27,1.82,3.71,4.14,4.12c0.95,0.17,1.93,0.27,2.89,0.28
        c2.78,0.05,5.56,0.07,8.34,0.04c1.27-0.01,2.55-0.09,3.81-0.27c2.23-0.33,3.74-1.76,4.2-3.92c0.18-0.85,0.28-1.73,0.3-2.6
        C26.6,235.38,26.57,233.52,26.57,231.65z"/>
      <path d="M14.63,224.57c4.15,0,7.49,3.34,7.51,7.49c0.01,4.15-3.36,7.52-7.51,7.51c-4.15-0.01-7.49-3.36-7.49-7.5
        C7.14,227.91,10.48,224.57,14.63,224.57z M14.63,227.2c-2.7,0-4.86,2.17-4.87,4.86c0,2.7,2.16,4.87,4.86,4.87
        c2.71,0,4.89-2.17,4.88-4.88C19.49,229.36,17.33,227.2,14.63,227.2z"/>
      <path d="M22.44,222.52c0.97,0,1.74,0.76,1.75,1.73c0.01,0.98-0.77,1.77-1.74,1.77c-0.96,0-1.75-0.78-1.76-1.73
        C20.68,223.3,21.46,222.52,22.44,222.52z"/>
    </g>
    <g>
      <path d="M9.68,158.49c19.99,1.35,37.93-3.43,54.25-15.75c-17.13-1.58-28.4-10.2-34.76-26.1c5.61,0,10.86,0,16.1,0
        c0.03-0.2,0.06-0.41,0.09-0.61c-18.04-5.65-27.75-17.8-29.18-37.15c5.46,2.62,10.62,4.43,16.72,4.49
        c-9.44-7.6-15.29-16.79-16.24-28.61c-0.6-7.43,1.39-14.31,5.11-21.06c20.2,23.58,45.39,36.84,76,38.79c0-4.17-0.26-8.01,0.04-11.8
        c1.53-19.32,18.12-34.22,37.56-33.9c9.93,0.16,18.45,3.74,25.6,10.6c0.9,0.86,1.64,1.13,2.92,0.82c6.93-1.7,13.58-4.11,19.85-7.54
        c0.39-0.21,0.78-0.42,1.19-0.59c0.1-0.04,0.26,0.04,0.67,0.12c-2.77,8.34-7.95,14.7-15.22,19.5c0.12,0.25,0.23,0.49,0.35,0.74
        c6.48-1.8,12.95-3.59,19.62-5.44c-0.51,0.76-1.02,1.58-1.59,2.37c-4.23,5.87-9.26,10.98-15.03,15.33
        c-1.28,0.97-1.68,1.91-1.63,3.49c1.33,41.63-23.41,82.69-60.96,99.17c-33.83,14.85-67.25,12.85-99.48-5.57
        C10.96,159.39,10.33,158.92,9.68,158.49z"/>
    </g>
  </svg>
  )
}

export default SVGTwitterHeaderIcon;