import "./style.css";
import Dropdown from "../HeaderDropdown";
import SVGInstagramHeaderIcon from "../Utils/SVG/Header/SVGInstagramHeaderIcon";
import SVGTwitterHeaderIcon from "../Utils/SVG/Header/SVGTwitterHeaderIcon";

const Header = () => {    

    return (
        <div className="Header">
            <div className="Mobile">
                <div className="Logo">
                    <img src='/resources/images/header/logoheaderGuaranaColorM.svg' style={{width: "10rem"}} alt="Guarana Studio"/>
                </div>
                <div className="Dropdown">
                    {/* {selectedOption && (<div>Opção: {selectedOption}</div>)} */}
                   <Dropdown 
                        options={[                              
                            // {opt: "Games", id: "#games"},                  
                            {opt: "About us", id: "#aboutUs"},
                            {opt: "Contact", id: "#contact"},
                            {opt: "Privacy", id: "/copyright"}                      
                        ]}
                    // onSelect={setSelectedOption}
                    />
                </div>
            </div>
            <div className="Container">
                <img src='/resources/images/header/logoheaderGuaranaColor.svg' style={{width: "5rem"}} alt="Guarana Studio"/>
                <ul className="Itens">
                    {/* <li>
                        <a href="#games">
                            <b>Games</b>
                        </a>
                    </li> */}
                    <li>
                        <a href="#aboutUs">
                            <b>About us</b>
                        </a>
                    </li>
                    <li>
                        <a href="#contact">
                            <b>Contact</b>
                        </a>
                    </li>
                    <li>
                        <a href="/copyright">
                            <b>Privacy</b>
                        </a>
                    </li>
                </ul>    
                <ul className="Icons">
                    <li>
                        <a href = "https://www.instagram.com/guaranagames/" rel="noreferrer" target="_blank">                            
                            <SVGInstagramHeaderIcon fill="black" psize="30px" hovercolor="#F01D43"/>
                        </a>
                    </li>
                    <li>                                                
                         <a href = "https://twitter.com/guaranaGame" rel="noreferrer" target="_blank">
                            <SVGTwitterHeaderIcon fill="black" psize="30px" hovercolor="#F01D43"/>
                             {/* <img src={twitter} alt='Guarana Studio on Twitter'/> */}
                        </a>
                    </li>
                </ul>    
            </div>
        </div>
    );
}

export default Header;