import AboutUsPage from './pages/aboutUs';
import TermsAndConditionsPage from './pages/TermsAndConditions';
import CopyrightPage from './pages/Copyright';
import { 
  BrowserRouter as Router, 
  Routes,
  Route
  } from 'react-router-dom';

const App = () => (    
  <div>
    <Router>
        <Routes>    
          <Route path="/" element={<AboutUsPage />}/> 
          <Route path="/terms" element={<TermsAndConditionsPage />}/>
          <Route path="/copyright" element={<CopyrightPage />}/>
        </Routes>
      </Router>
  </div>
);

export default App;
