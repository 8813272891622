import { useState } from "react";

const SVGTwitterCircleIcon = (props) => {

  const [fillCollor, setFillCollor] = useState(props.fill);

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 200 200" 
    
    onMouseOver={() => setFillCollor(props.hovercolor)} 
    onMouseOut={() => setFillCollor(props.fill)} 
    style={{width: props.psize, heigth: props.psize}}>

    <circle fill={fillCollor} cx="100" cy="99.8" r="100"/>
    <g>
      <path fill="white" d="M33.07,143.6c14.81,1,28.11-2.54,40.2-11.67c-12.7-1.17-21.04-7.56-25.76-19.34c4.16,0,8.05,0,11.93,0
        c0.02-0.15,0.04-0.3,0.07-0.45c-13.37-4.19-20.56-13.19-21.63-27.53c4.05,1.94,7.87,3.28,12.39,3.33
        c-7-5.63-11.33-12.44-12.04-21.2c-0.44-5.5,1.03-10.61,3.79-15.61c14.97,17.47,33.63,27.3,56.32,28.75c0-3.09-0.19-5.93,0.03-8.74
        c1.14-14.32,13.43-25.36,27.83-25.12c7.36,0.12,13.68,2.78,18.97,7.86c0.66,0.64,1.22,0.84,2.16,0.61
        c5.14-1.26,10.06-3.05,14.71-5.59c0.29-0.16,0.58-0.31,0.88-0.44c0.08-0.03,0.19,0.03,0.5,0.09c-2.05,6.18-5.89,10.89-11.28,14.45
        c0.09,0.18,0.17,0.37,0.26,0.55c4.8-1.33,9.6-2.66,14.54-4.03c-0.38,0.57-0.76,1.17-1.17,1.75c-3.13,4.35-6.86,8.13-11.14,11.36
        c-0.95,0.72-1.25,1.41-1.21,2.58c0.98,30.85-17.35,61.28-45.17,73.49c-25.07,11-49.84,9.53-73.72-4.13
        C34.02,144.27,33.55,143.92,33.07,143.6z"/>
    </g>
    </svg>
  )
}

export default SVGTwitterCircleIcon;